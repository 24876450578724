import { fetchAPI } from "./api"

export async function getLocalizedPage(targetLocale, pageContext) {
  const localization = pageContext.localizations.find(
    localization => localization.locale === targetLocale
  )
  try{
    const localePage = await fetchAPI(`/pages/${localization.id}`)
    return localePage
  }
  catch (e){
    const localePage = await fetchAPI(`/articulos/${localization.id}`)
    return localePage
  }



}
