import React, { useEffect, useRef, useState } from "react";
import { navigate } from "gatsby-link"
import { getLocalizedPage } from "@/utils/localize-fetch"
import { localizePath } from "@/utils/localize"
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useCookies } from "react-cookie"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { alpha, useTheme } from "@mui/material/styles"
import Grid from "@mui/material/Grid"


const LocaleSwitch = ({ pageContext, colorInvert = false }) => {
  const theme = useTheme()
  const isMounted = useRef(false)
  const select = useRef()
  const [, setShowing] = useState(false)
  const [cookies, setCookie] = useCookies()
  const id = "trans"
  const [locale, setLocale] = useState(
    cookies.GATSBY_LOCALE || pageContext.locale
  )

  const handleLocaleChange = async selectedLocale => {
    setCookie("GATSBY_LOCALE", selectedLocale, {
      path: "/",
      secure: process.env.NODE_ENV,
      sameSite: "Strict"
    })
    setLocale(selectedLocale)
  }

  const handleLocaleChangeRef = useRef(handleLocaleChange)

  const changeLocale = async () => {
    setShowing(false)
    if (
      !isMounted.current &&
      cookies.GATSBY_LOCALE &&
      cookies.GATSBY_LOCALE !== pageContext.locale
    ) {
      // Redirect to locale page if locale mismatch
      const localePage = await getLocalizedPage(
        cookies.GATSBY_LOCALE,
        pageContext
      )
      await navigate(localizePath({ ...pageContext, ...localePage }))
    }
  }

  useEffect(() => {
    // Set the requested locale when no cookie locale is found
    if (!cookies.GATSBY_LOCALE) {
      handleLocaleChangeRef.current(pageContext.defaultLocale)
    }

    changeLocale()

    return () => {
      isMounted.current = true
    }
  }, [locale, pageContext, cookies.GATSBY_LOCALE])

  const [anchorEl, setAnchorEl] = useState(null)
  const [openedPopoverId, setOpenedPopoverId] = useState(null)

  const handleClick = (event, popoverId) => {
    setAnchorEl(event.target)
    setOpenedPopoverId(popoverId)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenedPopoverId(null)
  }

  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  const hasActiveLink = () => pageContext.localizedPaths?.find((i) => i.href === activeLink)
  const linkColor = colorInvert ? 'common.white' : 'text.primary';

  return (
    <Box marginLeft={4}>
      <Box
        display={"flex"}
        alignItems={"center"}
        aria-describedby={id}
        sx={{ cursor: "pointer" }}
        onClick={(e) => handleClick(e, id)}
      >
        <Typography
          fontWeight={openedPopoverId === id || hasActiveLink() ? 700 : 400}
          color={linkColor}
          style={{ textTransform: "uppercase" }}
        >
          {locale}
        </Typography>
        <ExpandMoreIcon
          sx={{
            marginLeft: theme.spacing(1 / 4),
            width: 16,
            height: 16,
            transform: openedPopoverId === id ? 'rotate(180deg)' : 'none',
            color: linkColor,
          }}
        />
      </Box>
      <Popover
        elevation={3}
        id={id}
        open={openedPopoverId === id}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        sx={{
          ".MuiPaper-root": {
            maxWidth: pageContext.localizedPaths.length > 12 ? 350 : 250,
            padding: 2,
            marginTop: 2,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderTop: `3px solid ${theme.palette.primary.main}`
          }
        }}
      >
        <Grid container direction={"column"} spacing={0.5}>
          {pageContext.localizedPaths?.map(({ href, locale }, i) => (
            <Grid item key={i}>
              <Button
                component={'a'}
                href={href}
                fullWidth
                onClick={() => handleLocaleChange(locale)}
                style={{ textTransform: "uppercase" }}
                sx={{
                  justifyContent: 'flex-start',
                  color:
                    activeLink === href
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  backgroundColor:
                    activeLink === href
                      ? alpha(theme.palette.primary.main, 0.1)
                      : 'transparent',
                  fontWeight: activeLink === href ? 600 : 400,
                }}
              >
                {locale}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </Box>

  )
}

export default LocaleSwitch
